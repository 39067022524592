<template>
  <div class="text-container">
    <p>
      In Zeiten des Online-Handels ist es besonders wichtig, einen gut
      durchdachten Werbeauftritt, strategisch sinnvolles Marketing und eine
      klare Kundenansprache aufzubauen.
    </p>

    <p>Drei Punkte sind von Bedeutung:</p>
    <ol>
      <li>
        <span>Positionierung</span>
        <ul>
          <li>Klare Strategie in Ihrer Offizin</li>
          <li>Alleinstellungsmerkmal entwickeln</li>
          <li>Kenntnis vom Marktumfeld und Konkurrenten</li>
        </ul>
      </li>
      <li>
        <span>Zielgruppe</span>
        <ul>
          <li>Kundenverhalten kennen</li>
          <li>Zielgruppe kennen bzw. ermitteln</li>
          <li>
            Kundenwünsche und Kundenbedürfnisse kennen mittels Kundenumfrage
          </li>
        </ul>
      </li>
      <li>
        <span>Marketingmix</span>
        <ul>
          <li>Gestaltung der Offizin (Warenpräsentation)</li>
          <li>digitale Werbung über Website und Newsletter</li>
          <li>klassische Werbung, z. B. Handzettel</li>
        </ul>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir helfen Ihnen beim Aufbau eines gut durchdachten Werbeauftritts und einer klaren Kundenansprache.",
      },
    ],
  },
};
</script>